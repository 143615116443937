import React, { ReactNode } from 'react'
import { BuyerAutocompleteDetails } from '../../../types/buyerAutocompleteDetails'
import {
  isRiskApproved,
  isRiskInReview,
  isRiskOnHold,
  isRiskRejected,
  isRiskSubmitted
} from '../../../utils/riskDecisionUtils'
import RejectedBox from './RejectedBox'
import InReviewBox from './InReviewBox'
import BuyerInsufficientFundsBox from './BuyerInsufficientFundsBox'
import ValidBox from './ValidBox'
import OnboardingNotStartedBox from './OnboardingNotStartedBox'
import {
  isOnboardingStatusFailed,
  isOnboardingStatusInProgress,
  isOnboardingStatusManualReview,
  isOnboardingStatusNotStarted
} from '../../../types/onboardingStatuses'
import OnboardingInProgressBox from './OnboardingInProgressBox'
import { GetMarketplaceSettingsResponse } from '../../../types/GetMarketplaceSettingsResponse'

type BuyerInformationBoxProps = {
  buyerCompany: BuyerAutocompleteDetails | null
  marketplaceSettings: GetMarketplaceSettingsResponse | undefined
}

const BuyerInformationBox = ({ buyerCompany, marketplaceSettings }: BuyerInformationBoxProps): ReactNode => {
  if (
    isRiskRejected(buyerCompany?.riskDecision) ||
    (isRiskApproved(buyerCompany?.riskDecision) &&
      isOnboardingStatusFailed(buyerCompany?.companyOnboardingStatus) &&
      marketplaceSettings?.areOnboardingChecksRequiredForLimitedCompanies)
  ) {
    return <RejectedBox />
  }

  if (
    isRiskInReview(buyerCompany?.riskDecision) ||
    isRiskSubmitted(buyerCompany?.riskDecision) ||
    isRiskOnHold(buyerCompany?.riskDecision)
  ) {
    return <InReviewBox />
  }

  if (
    isRiskApproved(buyerCompany?.riskDecision) &&
    isOnboardingStatusNotStarted(buyerCompany?.companyOnboardingStatus) &&
    marketplaceSettings?.areOnboardingChecksRequiredForLimitedCompanies
  ) {
    return <OnboardingNotStartedBox companyId={buyerCompany?.companyId} />
  }

  if (
    isRiskApproved(buyerCompany?.riskDecision) &&
    (isOnboardingStatusInProgress(buyerCompany?.companyOnboardingStatus) ||
      isOnboardingStatusManualReview(buyerCompany?.companyOnboardingStatus)) &&
    marketplaceSettings?.areOnboardingChecksRequiredForLimitedCompanies
  ) {
    return <OnboardingInProgressBox />
  }

  if (isRiskApproved(buyerCompany?.riskDecision) && buyerCompany?.availableSpendingLimit === 0) {
    return (
      <BuyerInsufficientFundsBox
        availableSpendingLimit={buyerCompany?.availableSpendingLimit}
        totalSpendingLimit={buyerCompany?.totalSpendingLimit}
        currency={buyerCompany?.currencyCode}
      />
    )
  }

  return (
    <ValidBox
      availableSpendingLimit={buyerCompany?.availableSpendingLimit}
      totalSpendingLimit={buyerCompany?.totalSpendingLimit}
      currency={buyerCompany?.currencyCode}
    />
  )
}

export default BuyerInformationBox
