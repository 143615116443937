import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { useFetchBuyersAutocompleteData } from '../../custom-hooks/useFetchBuyersAutocompleteData'
import useDebounce from '../../custom-hooks/useDebounce'
import content from '../../pages/content'
import { BuyerAutocompleteDetails } from '../../types/buyerAutocompleteDetails'
import { SxProps, Theme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import routes from '../../routes'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const componentContent = content.components.searchComponent
const minimumCharactersForSearch = 3
const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: BuyerAutocompleteDetails) => {
  if (option.companyId === undefined) {
    return (
      <Box
        data-cy="no-match-box"
        sx={{
          p: theme.spacing(8, 10, 8, 21),
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.common.grey50
        }}
      >
        <Typography variant="inputText">No matching companies found</Typography>
        <Link variant="inputText" href={routes.customerOnboarding}>
          You can onboard a new customer here
          <ArrowForwardIcon sx={{ fontSize: '12px' }} />
        </Link>
      </Box>
    )
  }

  const companyNumber = (option.nationalIdentifiers || []).join(', ')

  return (
    <Box component="li" {...props} key={companyNumber}>
      <Typography variant="inputText">{option.companyName}</Typography>
      <Typography
        variant="body2"
        sx={{
          marginLeft: theme.spacing(3)
        }}
      >
        {companyNumber}
      </Typography>
    </Box>
  )
}

type SearchProps = {
  defaultValue: BuyerAutocompleteDetails | null
  onValueSelected: (option: BuyerAutocompleteDetails | null) => void
  sx?: SxProps<Theme>
}
export const SearchComponent = ({ defaultValue, onValueSelected, sx }: SearchProps) => {
  const [autocompleteValue, setAutocompleteValue] = useState<BuyerAutocompleteDetails | null>(defaultValue)

  const [inputValue, setInputValue] = useState<string>(defaultValue?.companyName ?? '')

  const debouncedSearch = useDebounce(inputValue, 1000)
  const { isLoading, data } = useFetchBuyersAutocompleteData(debouncedSearch, minimumCharactersForSearch)

  const filterOptions = (options: BuyerAutocompleteDetails[]) => {
    if (debouncedSearch && debouncedSearch.length >= minimumCharactersForSearch && options.length === 0 && !isLoading) {
      options.push({} as BuyerAutocompleteDetails)
    }

    return options
  }

  return (
    <Box
      sx={{
        p: {
          mobile: theme.spacing(7, 0, 0),
          xs: theme.spacing(6, 0, 0)
        },
        ...sx
      }}
    >
      <Autocomplete
        id="company-search-autocomplete"
        data-cy="company-search-autocomplete"
        value={autocompleteValue}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
        freeSolo={true}
        onChange={(_, value, reason) => {
          if (reason === 'createOption') {
            onValueSelected(null)
            return
          }
          if (reason !== 'selectOption') {
            setAutocompleteValue(null)
            onValueSelected(null)
            return
          }
          if ((value as BuyerAutocompleteDetails) !== undefined) {
            const companyValue = value as BuyerAutocompleteDetails
            setAutocompleteValue(companyValue)
            onValueSelected(companyValue)
          }
        }}
        options={data || []}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            placeholder={componentContent.placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        getOptionLabel={(option) => (typeof option === 'object' ? option.companyName || '' : option)}
        onInputChange={(_event, value, reason) => {
          if (reason === 'reset') {
            return
          }
          setInputValue(value)
        }}
        renderOption={renderOption}
      />
    </Box>
  )
}
