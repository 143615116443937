import React from 'react'
import content from '../../content'
import InformationBox, { InformationBoxRow } from '../../../components/informationBox/InformationBox'
import routes from '../../../routes'
import theme from '../../../assets/theme'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

const informationContent = content.pages.customerInformation.onboardingNotStarted

const StyledLink = styled(Link)`
  padding: ${theme.spacing(2, 6)};
  color: ${theme.palette.common.panels['warning'].color};
  text-decoration: none;
  border-radius: 100px;
  border: solid 1px ${theme.palette.common.panels['warning'].color};
`

type OnboardingNotStartedBoxProps = {
  companyId: string | undefined
}
const OnboardingNotStartedBox = ({ companyId }: OnboardingNotStartedBoxProps) => {
  if (!companyId) {
    return null
  }
  const activatePath = routes.buyer.replace(':companyId', companyId)
  return (
    <InformationBox title={informationContent.title} type={'warning'}>
      <InformationBoxRow sx={{ paddingTop: theme.spacing(4) }}>
        <StyledLink to={activatePath}>
          <Typography
            variant={'subtitle2'}
            component={'span'}
            sx={{ color: theme.palette.common.panels['warning'].color }}
          >
            {informationContent.linkTitle}
          </Typography>
        </StyledLink>
      </InformationBoxRow>
    </InformationBox>
  )
}

export default OnboardingNotStartedBox
