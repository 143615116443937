import React from 'react'
import theme from '../../assets/theme'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link, useLocation } from 'react-router-dom'
import routes from '../../routes'
import Container from '@mui/material/Container'
import content from '../../pages/content'
import { UserDetails } from '../../types/UserDetails'
import Role from '../../types/enums/Role'
import { styled } from '@mui/material/styles'

const componentContent = content.navBar.impersonation

const StyledLink = styled(Link)`
  :active {
    color: ${theme.palette.primary.main};
  }

  :hover {
    color: ${theme.palette.primary.main};
  }

  :focus {
    color: ${theme.palette.primary.main};
  }
`

interface ImpersonationBarProps {
  userInfo: UserDetails
}

export const ImpersonationBar = ({ userInfo }: ImpersonationBarProps) => {
  const location = useLocation()

  const shouldBeDisplayed = () => {
    return (
      userInfo?.marketplace?.name &&
      (userInfo.roles.includes(Role.KriyaUser) || userInfo.isAssignedToMultipleMerchants) &&
      location.pathname !== routes.impersonate
    )
  }

  if (!shouldBeDisplayed()) {
    return <></>
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: theme.palette.common.backgroundGrey100
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
          gap: theme.spacing(5)
        }}
      >
        <Box sx={{ display: 'flex' }} data-cy="impersonation-header">
          <Typography variant="body3" sx={{ color: theme.palette.common.textGrey700, whiteSpace: 'break-spaces' }}>
            {componentContent.title}
          </Typography>
          <Typography variant="h6">{userInfo.marketplace?.name}</Typography>
        </Box>
        <StyledLink to={routes.impersonate}>
          <Typography
            variant="boldHelperText2"
            sx={{
              color: theme.palette.primary.main
            }}
          >
            {componentContent.switchAction}
          </Typography>
        </StyledLink>
      </Stack>
    </Container>
  )
}
