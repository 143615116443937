import unleashApiKey from './unleashApiKeyMapping'

export const unleashConfig = {
  url: 'https://flags.kriya.co/api/frontend',
  clientKey: unleashApiKey(window.location.href),
  refreshInterval: 15,
  appName: 'Merchant Portal'
}

export const CompanyOnboardingWithBusinessStructure = 'ENT-440_merchant_portal_onboaridng_with_business_type'
