import { BuyerAutocompleteDetails } from '../types/buyerAutocompleteDetails'
import { isRiskApproved } from './riskDecisionUtils'
import { isOnboardingStatusPassed } from '../types/onboardingStatuses'
import { GetMarketplaceSettingsResponse } from '../types/GetMarketplaceSettingsResponse'

export const canOrderingProcessForCompanyBeContinued = (
  value: BuyerAutocompleteDetails | null,
  marketplaceSettings: GetMarketplaceSettingsResponse | undefined
) => {
  const hasLimit = (value?.availableSpendingLimit || 0) > 0

  return (
    value == null ||
    ((marketplaceSettings?.areOnboardingChecksRequiredForLimitedCompanies === false ||
      isOnboardingStatusPassed(value.companyOnboardingStatus ?? '')) &&
      isRiskApproved(value?.riskDecision) &&
      hasLimit)
  )
}
