import { Controller, UseFormReturn } from 'react-hook-form'
import React, { useState } from 'react'
import { availableCurrencies, defaultCurrencyCode } from '../../consts'
import EditComponent from './EditComponent'
import EditConfirmationDialog from './EditConfirmationDialog'
import { CreateOrderFormValues, FormState } from './CreateOrderPage'
import MenuItem from '@mui/material/MenuItem'
import FormComponent from '../../components/form/FormComponent'
import { SearchComponent } from '../../components/autocomplete/SearchComponent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import content from '../content'
import { formatCurrency } from '../../utils/formatCurrency'
import { hasDifferentOrderCurrency } from '../../utils/multiCurrency'
import theme from '../../assets/theme'
import BuyerInformationBox from './informationBoxes/BuyerInformationBox'
import MerchantInsufficientFundsBox from './informationBoxes/MerchantInsufficientFundsBox'
import { GetMerchantSpendingLimitResponse } from '../../types/GetMerchantSpendingLimitResponse'
import { GetMarketplaceSettingsResponse } from '../../types/GetMarketplaceSettingsResponse'
import { notEmptyOrWhitespace } from '../../validation/formValidators'

const pageContent = content.pages.createOrder.orderDetails

interface OrderDetailsProps {
  form: UseFormReturn<CreateOrderFormValues>
  formState: FormState
  onEdit: () => void
  onCurrencyChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  exchangeRate: number
  gbpExchangeRate: number
  isCompanyEligible: boolean
  merchantSpendingLimit?: GetMerchantSpendingLimitResponse
  marketplaceSettings?: GetMarketplaceSettingsResponse
}

const OrderDetails = ({
  form,
  formState,
  onEdit,
  onCurrencyChange,
  exchangeRate,
  gbpExchangeRate,
  isCompanyEligible,
  merchantSpendingLimit,
  marketplaceSettings
}: OrderDetailsProps) => {
  const { setValue } = form
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const selectedOrderCurrency = form.getValues('currencyCode')

  const buyerSpendingLimitExceeded = (value: number) => {
    const buyerCompany = form.getValues('buyer')
    const availableSpendingLimit = buyerCompany?.availableSpendingLimit || 0
    const originalBuyerCurrency = buyerCompany?.currencyCode || defaultCurrencyCode

    if (hasDifferentOrderCurrency(selectedOrderCurrency, originalBuyerCurrency)) {
      const orderAmount = value
      const finalAmountAfterConversion = orderAmount * exchangeRate

      return finalAmountAfterConversion <= availableSpendingLimit
    }

    return value <= availableSpendingLimit
  }

  const merchantSpendingLimitExceeded = (value: number) => {
    const availableSpendingLimit = merchantSpendingLimit?.availableSpendingLimit ?? 0
    const amountAfterConversion = (value * gbpExchangeRate * (merchantSpendingLimit?.advanceRate ?? 100)) / 100
    const result = amountAfterConversion <= availableSpendingLimit
    return result
  }

  const editProps = {
    isDialogOpen: isDialogOpen,
    handleDialogClose: () => setIsDialogOpen(false),
    handleDialogShow: () => setIsDialogOpen(true),
    onEdit: () => {
      setIsDialogOpen(false)
      onEdit()
    }
  }

  return (
    <div
      style={{
        marginTop: theme.spacing(32)
      }}
    >
      <Typography variant={'h2'}>{pageContent.title}</Typography>

      <FormComponent
        label={pageContent.fields.customer.label}
        formField={'buyer'}
        form={form}
        tooltip={pageContent.fields.customer.tooltip}
        component={
          <>
            <SearchComponent
              defaultValue={null}
              onValueSelected={(company) => {
                setValue('currencyCode', company?.currencyCode || defaultCurrencyCode)
                setValue('contactDetails.emailAddress', company?.contactDetails?.emailAddress || '')
                setValue('contactDetails.fullName', company?.contactDetails?.fullName || '')
                setValue('contactDetails.phoneNumber', company?.contactDetails?.phoneNumber || '')
                setValue('buyer', company, { shouldDirty: true })
              }}
              sx={{
                display: formState === FormState.OrderDetails ? 'inherit' : 'none'
              }}
            />
            {formState !== FormState.OrderDetails && (
              <EditComponent content={form.getValues('buyer')?.companyName || ''} {...editProps} />
            )}
          </>
        }
      />

      {(form.getValues('buyer')?.nationalIdentifiers?.length || 0) > 0 && (
        <FormComponent
          label={pageContent.fields.nationalId.label}
          formField={'buyer'}
          form={form}
          component={
            <Typography variant={'body1'}>{(form.getValues('buyer')?.nationalIdentifiers || []).join(', ')}</Typography>
          }
        />
      )}

      {form.getValues('buyer') && (
        <FormComponent
          component={
            <BuyerInformationBox buyerCompany={form.getValues('buyer')} marketplaceSettings={marketplaceSettings} />
          }
          mergeLabel={true}
          hideState={true}
        />
      )}

      {isCompanyEligible && (
        <>
          <FormComponent
            label={pageContent.fields.invoiceReferenceNumber.label}
            formField={'invoiceReferenceNumber'}
            form={form}
            component={
              <Controller
                control={form.control}
                name={'invoiceReferenceNumber'}
                rules={{
                  required: true,
                  validate: {
                    notEmptyOrWhitespace
                  }
                }}
                render={({ field }) => <TextField variant="outlined" {...field} sx={{ width: '60%' }} />}
              />
            }
          />

          <FormComponent
            label={pageContent.fields.currency.label}
            formField={'currencyCode'}
            form={form}
            component={
              <>
                {formState === FormState.OrderDetails && (
                  <Controller
                    control={form.control}
                    name={'currencyCode'}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-select-currency"
                        sx={{ width: '60%' }}
                        {...field}
                        select
                        onChange={(e) => {
                          field.onChange(e)
                          onCurrencyChange(e)
                        }}
                      >
                        {availableCurrencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )}

                {formState !== FormState.OrderDetails && (
                  <EditComponent content={form.getValues('currencyCode')} {...editProps} />
                )}
              </>
            }
          />

          <FormComponent
            label={pageContent.fields.amount.label}
            formField={'amount'}
            form={form}
            tooltip={pageContent.fields.amount.tooltip}
            component={
              <>
                {formState === FormState.OrderDetails && (
                  <Controller
                    control={form.control}
                    name={'amount'}
                    rules={{
                      required: true,
                      min: 0.01,
                      validate: {
                        merchantInsufficientFunds: (value) =>
                          !merchantSpendingLimit?.isHardLimit || merchantSpendingLimitExceeded(Number(value)),
                        insufficientFunds: (value) => buyerSpendingLimitExceeded(Number(value))
                      }
                    }}
                    render={({ field }) => (
                      <div>
                        <TextField
                          variant="outlined"
                          type={'number'}
                          {...field}
                          sx={{ width: '60%', display: 'flex' }}
                        />
                        {(form.getFieldState('amount')?.error?.type === 'insufficientFunds' ||
                          form.getFieldState('amount')?.error?.type === 'merchantInsufficientFunds') && (
                          <Typography variant={'helperText'} sx={{ color: theme.palette.error.main }}>
                            {pageContent.fields.amount.errorMessages.insufficientFunds}
                          </Typography>
                        )}
                      </div>
                    )}
                  />
                )}

                {formState !== FormState.OrderDetails && (
                  <EditComponent
                    content={formatCurrency(form.getValues('amount'), selectedOrderCurrency)}
                    {...editProps}
                  />
                )}
              </>
            }
          />

          {form.getFieldState('amount')?.error?.type === 'merchantInsufficientFunds' && (
            <FormComponent
              component={
                <MerchantInsufficientFundsBox
                  assignedSpendingLimit={merchantSpendingLimit?.assignedSpendingLimit ?? 0}
                  availableSpendingLimit={merchantSpendingLimit?.availableSpendingLimit ?? 0}
                  gbpExchangeRate={gbpExchangeRate}
                  orderCurrency={selectedOrderCurrency}
                />
              }
              mergeLabel={true}
              hideState={true}
            />
          )}

          {formState !== FormState.OrderDetails && <EditConfirmationDialog {...editProps} />}
        </>
      )}
    </div>
  )
}

export default OrderDetails
