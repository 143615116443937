import { useQuery } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { GetMarketplaceSettingsResponse } from '../types/GetMarketplaceSettingsResponse'

export const fetchMarketplaceSettingsKey = 'fetch-marketplace-settings'

export const fetchMarketplaceSettings = async (): Promise<GetMarketplaceSettingsResponse> => {
  const response = await apiClient.get<GetMarketplaceSettingsResponse>('/marketplace')
  return response.data
}

export const useFetchMarketplaceSettings = () => {
  const { data, isLoading } = useQuery<GetMarketplaceSettingsResponse, Error>(
    [fetchMarketplaceSettingsKey],
    () => fetchMarketplaceSettings(),
    { retry, refetchOnWindowFocus: false }
  )
  return { data, isLoading }
}
