import React from 'react'
import Typography from '@mui/material/Typography'
import content from '../content'
import { Controller, UseFormReturn } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import FormComponent from '../../components/form/FormComponent'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { defaultCountryCode } from '../../consts'
import { isEmailValid } from '../../validation/emailValidation'
import theme from '../../assets/theme'
import { notEmptyOrWhitespace } from '../../validation/formValidators'
import { CreateOrderFormValues } from './CreateOrderPage'

const pageContent = content.pages.createOrder.contactDetails

interface CustomerContactDetailsProps {
  form: UseFormReturn<CreateOrderFormValues>
}

const CustomerContactDetails = ({ form }: CustomerContactDetailsProps) => {
  return (
    <div
      style={{
        marginTop: theme.spacing(32)
      }}
    >
      <Typography variant={'h2'}>
        {pageContent.title}{' '}
        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{pageContent.tooltip}</div>}>
          <InfoOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
        </Tooltip>
      </Typography>
      <Typography variant={'body1'} sx={{ marginBottom: theme.spacing(8) }}>
        {pageContent.heading}
      </Typography>

      <FormComponent
        label={pageContent.fields.fullName.label}
        formField={'contactDetails.fullName'}
        form={form}
        component={
          <Controller
            control={form.control}
            name={'contactDetails.fullName'}
            rules={{
              required: true,
              validate: {
                notEmptyOrWhitespace
              }
            }}
            render={({ field }) => <TextField variant="outlined" fullWidth {...field} />}
          />
        }
      />
      <FormComponent
        label={pageContent.fields.email.label}
        formField={'contactDetails.emailAddress'}
        form={form}
        component={
          <Controller
            control={form.control}
            name={'contactDetails.emailAddress'}
            rules={{
              required: true,
              validate: {
                email: (r) => isEmailValid(r)
              }
            }}
            render={({ field }) => <TextField variant="outlined" type={'email'} fullWidth {...field} />}
          />
        }
      />
      <FormComponent
        label={pageContent.fields.phoneNumber.label}
        formField={'contactDetails.phoneNumber'}
        form={form}
        component={
          <Controller
            control={form.control}
            name={'contactDetails.phoneNumber'}
            rules={{
              required: true,
              validate: {
                phoneNumber: (r) => !r || matchIsValidTel(r)
              }
            }}
            render={({ field }) => <MuiTelInput defaultCountry={defaultCountryCode} {...field} />}
          />
        }
      />
    </div>
  )
}

export default CustomerContactDetails
